<template>
  <div class="home">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ name: 'Home' }">{{
        app_name
      }}</el-breadcrumb-item>
      <el-breadcrumb-item>{{ $t("buttons.user_center") }}</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="page-block">
      <div class="page-block-header">
        <div class="content-title">
          <p>{{ $t("buttons.user_center") }}</p>
        </div>
      </div>
    </div>
    <div class="page-content-fill">
      <el-tabs v-model="activeTab">
        <el-tab-pane :label="$t('usercenter.basic_info')" name="info">
          <h4>{{ $t("login.user_name") }}</h4>
          <p>{{ user.statistics.name }}</p>
          <h4>{{ $t("login.email") }}</h4>
          <p>{{ user.statistics.email }}</p>
          <h4>{{ $t("usercenter.date_created") }}</h4>
          <p>
            {{ user.statistics.created_at | toLocalTime | formatLocalTime }}
          </p>
          <h4>{{ $t("usercenter.date_last_modified") }}</h4>
          <p>
            {{ user.statistics.modified_at | toLocalTime | formatLocalTime }}
          </p>
          <h4>{{ $t("usercenter.current_api_token") }}</h4>
          <el-input
            placeholder="API Token"
            :value="user.token"
            :readonly="true"
            id="api-token-frame"
          >
            <el-button slot="append" @click="copyToClipboard">{{
              $t("buttons.copy")
            }}</el-button>
          </el-input>
        </el-tab-pane>
        <el-tab-pane
          :label="$t('usercenter.account_settings')"
          name="info_change"
        >
          <h4>
            {{ $t("usercenter.info_change")
            }}{{
              user.statistics.name === "admin"
                ? " (" + $t("usercenter.admin_info") + ")"
                : ""
            }}
          </h4>
          <el-form :model="info_form">
            <el-form-item :label="$t('login.email')">
              <el-input
                v-model="info_form.email"
                :placeholder="user.statistics.email"
                :disabled="user.statistics.name === 'admin'"
              ></el-input>
            </el-form-item>
            <el-form-item :label="$t('login.user_name')">
              <el-input
                v-model="info_form.name"
                :placeholder="user.statistics.name"
                :disabled="user.statistics.name === 'admin'"
              ></el-input>
            </el-form-item>
          </el-form>
          <el-button
            type="primary"
            @click="infoChange"
            :disabled="user.statistics.name === 'admin'"
            >{{ $t("buttons.update_info") }}</el-button
          >
          <h4>{{ $t("buttons.update_password") }}</h4>

          <el-form :model="info_form">
            <el-form-item :label="$t('buttons.confirm_current_password')">
              <el-input v-model="info_form.password" type="password"></el-input>
            </el-form-item>
            <el-form-item :label="$t('usercenter.new_password')">
              <el-input
                v-model="info_form.new_password"
                type="password"
              ></el-input>
            </el-form-item>
          </el-form>
          <el-button type="primary" @click="infoChange">{{
            $t("buttons.update_password")
          }}</el-button>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import apiURL from "@/data/api";
// @ is an alias to /src
import { mapActions, mapState, mapGetters } from "vuex";
export default {
  name: "Dashboard",
  components: {},
  data() {
    return {
      activeTab: "info",
      show_token: false,
      info_form: {
        email: "",
        name: "",
        password: "",
        new_password: "",
      },
    };
  },
  beforeMount() {
    this.switchDefaultPage("/dashboard/user-center");
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
      app_name: (state) => state.app_name,
    }),
    ...mapGetters(["headers4reqs"]),
    token_hidden() {
      var th = [];
      if (this.user.token !== null) {
        for (let i = 0; i < this.user.token.length; i++) {
          th.push("*");
        }
      }
      return th.join("");
    },
    token_shown() {
      if (this.show_token) {
        return this.user.token;
      } else {
        return this.token_hidden;
      }
    },
  },
  mounted() {
    this.getCurrentUserInfo();
  },
  methods: {
    ...mapActions(["getCurrentUserInfo", "switchDefaultPage"]),
    copyToClipboard() {
      var _token_input = document.createElement("textarea");
      _token_input.value = this.user.token;
      document.body.appendChild(_token_input);
      _token_input.select();
      _token_input.setSelectionRange(0, 99999);
      document.execCommand("copy");
      document.body.removeChild(_token_input);
      this.$message.success(this.$t("usercenter.token_copied"));
    },
    infoChange() {
      this.$put_api(apiURL.user.me, this.info_form, null, this.headers4reqs, {})
        .then((res) => {
          console.log(res);
          if (res.id) {
            this.$router.go(this.$router.currentRoute);
          }
        })
        .catch((e) => {
          console.log(e);
          this.$message.error(this.$t("usercenter.info_error"));
        });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/main";
</style>

<style lang="scss" >
#api-token-frame {
  font-family: monospace !important;
}
</style>

